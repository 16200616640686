<template>
  <b-overlay :show="isLoading" rounded="lg">
  <b-card title="Add Role">
    <b-button to="/settings/role" variant="primary" type="filled"
      >Back to Role</b-button
    >

    <br /><br />
    <div class="row">
      <div class="col">
        <span>Name <span style="color: red">(*)</span></span>
        <b-form-input
          class="w-full"
          placeholder="Name"
          v-model="form.name"
          style="margin-top: 5px !important"
        />
      </div>

      <div class="col">
        <span>Level <span style="color: red">(*)</span></span>
        <b-form-input
          class="w-full"
          placeholder="Level"
          v-model="form.level"
          style="margin-top: 5px !important"
        />
      </div>
    </div>
    <br />

    <div class="row permission-border">
      <div class="col">
        <div class="row">
          <div class="col-6">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Permissions</span>
          </div>
          <div class="divider bg-dark" />
        </div>

        <div
          style="
            overflow-y: scroll;
            max-height: 500px;
            width: 100%;
            overflow-x: hidden;
          "
        >
          <div class="divider" />

          <!-- DASHBOARD -->
          <!-- <div class="row" style="margin-bottom: 10px">
            <div class="col">
              <strong style="font-size: 20px">Dashboard</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>View</strong>
              </div>
            </div>
          </div>

          <div
            v-for="(tr, index) in dashboard_menu"
            :key="'masterdata' + index"
          >
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.view"
                    @change="viewEvent(tr, 'dashboard_menu')"
                  ></b-form-checkbox>
                </div>
              </div>
            </div>
          </div> -->
          <!-- END DASHBOARD -->

          <div class="divider py-sm-1 bg-dark" />

          <!-- MASTERDATA -->
          <div class="row" style="margin-bottom: 10px">
            <div class="col">
              <strong style="font-size: 20px">Masterdata</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>View</strong>
              </div>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>Add</strong>
              </div>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>Edit</strong>
              </div>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>Delete</strong>
              </div>
            </div>
          </div>

          <div
            v-for="(tr, index) in masterdata_menu"
            :key="'masterdata' + index"
          >
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.view"
                    @change="viewEvent(tr, 'masterdata_menu')"
                  ></b-form-checkbox>
                </div>
              </div>

              <div
                class="col-2"
                v-if="tr.name !== 'Item' && tr.name !== 'Dealer'"
              >
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox v-model="tr.add"></b-form-checkbox>
                </div>
              </div>

              <div
                class="col-2"
                v-if="tr.name !== 'Item' && tr.name !== 'Dealer'"
              >
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox v-model="tr.edit"></b-form-checkbox>
                </div>
              </div>

              <div
                class="col-2"
                v-if="tr.name !== 'Item' && tr.name !== 'Dealer'"
              >
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox v-model="tr.delete"></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF MASTERDATA -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- DASHBOARD -->
          <div class="row" style="margin-bottom: 10px">
            <div class="col">
              <strong style="font-size: 20px">Dashboard</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>View</strong>
              </div>
            </div>
          </div>

          <div v-for="(tr, index) in dashboard_menu" :key="'dashboard' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.view"
                    @change="viewEvent(tr, 'dashboard_menu')"
                  ></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF DASHBOARD -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- ORDER -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Order Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in order_menu" :key="'order' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div
                class="col-2"
                v-for="(access, idx) in tr.access"
                :key="'access_order' + idx"
              >
                <div style="display: table; padding-left: 45%">
                  <b-form-checkbox
                    v-if="access === 'View'"
                    v-model="tr[access.toLowerCase()]"
                    @change="viewEvent(tr, 'order_menu')"
                    >{{ access }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    v-else-if="access === 'All'"
                    v-model="tr[access.toLowerCase()]"
                    >Access All PO</b-form-checkbox
                  >
                  <b-form-checkbox v-else v-model="tr[access.toLowerCase()]">{{
                    access
                  }}</b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF ORDER -->

          <!-- INVENTORY -->
          <div class="divider py-sm-1 bg-dark" />

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Inventory Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in inventory_menu" :key="'inventory' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div
                class="col-2"
                v-for="(access, idx) in tr.access"
                :key="'access_inventory' + idx"
              >
                <div style="display: table; padding-left: 45%">
                  <b-form-checkbox
                    v-if="access === 'View'"
                    v-model="tr[access.toLowerCase()]"
                    @change="viewEvent(tr, 'inventory_menu')"
                    >{{ access }}</b-form-checkbox
                  >
                  <b-form-checkbox v-else v-model="tr[access.toLowerCase()]">{{
                    access
                  }}</b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF INVENTORY -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- SALES ORDER -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Sales Order Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in sales_order_menu" :key="'so' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div
                class="col-2"
                v-for="(access, idx) in tr.access"
                :key="'access_so' + idx"
              >
                <div style="display: table; padding-left: 45%">
                  <b-form-checkbox
                    v-if="access === 'View'"
                    v-model="tr[access.toLowerCase()]"
                    @change="viewEvent(tr, 'sales_order_menu')"
                    >{{ access }}</b-form-checkbox
                  >
                  <b-form-checkbox v-else v-model="tr[access.toLowerCase()]">{{
                    access
                  }}</b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF SALES ORDER -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- DELIVERY ORDER -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Delivery Order Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in delivery_order_menu" :key="'do' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 4 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 4 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        @change="viewEvent(tr, 'delivery_order_menu')"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF DELIVERY ORDER -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- SELF COLLECT -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Self Collect Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in self_collect_menu" :key="'sc' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 4 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 4 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        @change="viewEvent(tr, 'self_collect_menu')"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF SELF COLLECT -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- SHIPPING -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Shipping Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in shipping_menu" :key="'sh' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 5 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 5 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 5 + column_idx]]"
                        @change="viewEvent(tr, 'shipping_menu')"
                        >{{
                          tr.access[row_idx * 5 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 5 + column_idx]]"
                        >{{
                          tr.access[row_idx * 5 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF SHIPPING -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- RETURN DELIVERY -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Return Delivery Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in return_delivery_menu" :key="'rd' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 6 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 6 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        @change="viewEvent(tr, 'return_delivery_menu')"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF RETURN DELIVERY -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- REDELIVERY -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Redelivery Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in redelivery_menu" :key="'rdlvr' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 6 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 6 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        @change="viewEvent(tr, 'redelivery_menu')"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF REDELIVERY -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- MANUAL CREATE -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Manual Create Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in manualcreate_menu" :key="'rdlvr' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 6 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 6 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        @change="viewEvent(tr, 'manualcreate_menu')"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF MANUAL CREATE -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- MASS DO UPDATE -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Utility Tools Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in utilitytools_menu" :key="'rdlvr' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 6 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 6 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        @change="viewEvent(tr, 'utilitytools_menu')"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF MASS DO UPDATE -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- RETRIGGER API -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Retrigger API Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in retrigger_api_menu" :key="'ra' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 4 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 4 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        @change="viewEvent(tr, 'retrigger_api_menu')"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF RETRIGGER API -->

          <!-- WARRANTY MASTER -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- WARRANTY MASTER -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Warranty Master Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>View</strong>
              </div>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>Add</strong>
              </div>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>Edit</strong>
              </div>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>Delete</strong>
              </div>
            </div>
          </div>

          <div
            v-for="(tr, index) in warranty_master_menu"
            :key="'warranty_master_menu' + index"
          >
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.view"
                    @change="viewEvent(tr, 'warranty_master_menu')"
                  ></b-form-checkbox>
                </div>
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.add"
                    v-if="tr.name !== 'Base Warranty' && tr.name !== 'Terms Condition' && tr.name !== 'System Promo'"
                  ></b-form-checkbox>
                </div>
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox v-model="tr.edit"></b-form-checkbox>
                </div>
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.delete"
                    v-if="tr.name !== 'Base Warranty' && tr.name !== 'Terms Condition'&& tr.name !== 'System Promo'"
                  ></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF WARRANTY MASTER -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- WARRANTY -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Warranty Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in warranty_menu" :key="'warranty' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 6 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 6 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        @change="viewEvent(tr, 'warranty_menu')"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >

                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 6 + column_idx]]"
                        >{{
                          tr.access[row_idx * 6 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF WARRANTY -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- Report -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Report</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>

            <div class="col-2">
              <div style="display: table; margin: 0 auto">
                <strong>View</strong>
              </div>
            </div>
          </div>

          <div v-for="(tr, index) in report_menu" :key="'report' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-2">
                <div style="display: table; margin: 0 auto">
                  <b-form-checkbox
                    v-model="tr.view"
                    @change="viewEvent(tr, 'report_menu')"
                  ></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF REPORT -->
          <div class="divider py-sm-1 bg-dark" />
          <!-- SETTINGS -->

          <div class="row" style="margin-bottom: 10px">
            <div class="col lg:w-full">
              <strong style="font-size: 20px">Settings Section</strong>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-2">
              <strong>Menu</strong>
            </div>
          </div>

          <div v-for="(tr, index) in settings_menu" :key="'setting' + index">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-2">
                {{ tr.name }}
              </div>

              <div class="col-9">
                <div
                  class="row"
                  style="margin-bottom: 10px"
                  v-for="(row, row_idx) in row(tr.access)"
                  :key="'access_row' + row_idx"
                >
                  <div
                    class="col-2"
                    v-for="(column, column_idx) in column"
                    :key="'access_column' + column_idx"
                  >
                    <div
                      style="display: table; padding-left: 65%"
                      v-if="row_idx * 4 + column_idx < tr.access.length"
                    >
                      <b-form-checkbox
                        v-if="tr.access[row_idx * 4 + column_idx] === 'View'"
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        @change="viewEvent(tr, 'settings_menu')"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-else
                        v-model="tr[tr.key[row_idx * 4 + column_idx]]"
                        >{{
                          tr.access[row_idx * 4 + column_idx]
                        }}</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF SETTINGS -->
        </div>
      </div>
    </div>
    <br />

    <div>
      <b-button :disabled="isLoading" class="float-right" variant="primary" @click="addNewRole"
        >Save</b-button
      ><br /><br />
    </div>
  </b-card>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        name: "",
        level: "",
        permission: {},
      },
      isLoading: false,
      column: [...Array(6).keys()],

      masterdata_menu: [
        {
          name: "Item",
          view: false,
        },
        {
          name: "Bundles",
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "Dealer",
          view: false,
          edit: false,
        },
        {
          name: "Post Code",
          view: false,
          add: false,
          edit: false,
          delete: false,
        },
        {
          name:"Industry Type",
          view: false,
          add: false,
          edit: false,
          delete: false,
        },
        {
          name: "Master PH",
          view: false,
          add: false,
          edit: false,
          delete: false,
        },
      ],
      dashboard_menu: [
        {
          name: "Dealer",
          view: false,
        },
        {
          name: "Sales Admin",
          view: false,
        },
      ],
      warranty_master_menu: [
        {
          name: "Base Warranty",
          view: false,
          edit: false,
        },
        {
          name: "Option Warranty",
          view: false,
          edit: false,
        },
        {
          name: "System Promo",
          view: false,
          edit: false,
        },
        {
          name: "Compressor Warranty",
          view: false,
          add: false,
          edit: false,
          delete: false,
        },
        {
          name: "PCode",
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "Campaign",
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "Promo Code",
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "Terms Condition",
          view: false,
          edit: false,
        },
      ],
      order_menu: [
        {
          name: "Order",
          access: ["View", "All", "Add", "Edit", "Delete"],
          view: false,
          all: false,
          add: false,
          edit: false, //for draft
          delete: false, //for draft
        },
      ],
      inventory_menu: [
        {
          name: "View Inventory",
          access: ["View", "Edit"],
          view: false,
          edit: false,
        },
      ],
      sales_order_menu: [
        {
          name: "View Sales Order",
          access: ["View"],
          view: false,
        },
      ],
      delivery_order_menu: [
        {
          name: "Delivery Orders Daikin",
          access: ["View", "Pick", "Unpick", "Bulk"],
          key: ["view", "pick", "unpick", "bulk"],
          view: false,
          pick: false,
          unpick: false,
          bulk: false,
        },
        {
          name: "Delivery Orders Sumitomo",
          access: ["View", "Pick", "Unpick", "Bulk"],
          key: ["view", "pick", "unpick", "bulk"],
          view: false,
          pick: false,
          unpick: false,
          bulk: false,
        },
      ],
      self_collect_menu: [
        {
          name: "Self Collect Daikin",
          access: ["View", "Collected", "Return"],
          key: ["view", "collected", "return"],
          view: false,
          collected: false,
          return: false,
        },
        {
          name: "Self Collect Sumitomo",
          access: ["View", "Collected", "Return"],
          key: ["view", "collected", "return"],
          view: false,
          collected: false,
          return: false,
        },
      ],
      shipping_menu: [
        {
          name: "Shipping Daikin",
          access: ["View", "Deliver", "Arrived", "Return", "Redelivery"],
          key: ["view", "deliver", "arrived", "return", "redelivery"],
          view: false,
          return: false,
          deliver: false,
          arrived: false,
          redelivery: false,
        },
        {
          name: "Shipping Sumitomo",
          access: ["View", "Deliver", "Arrived", "Return", "Redelivery"],
          key: ["view", "deliver", "arrived", "return", "redelivery"],
          view: false,
          return: false,
          deliver: false,
          arrived: false,
          redelivery: false,
        }
      ],
      return_delivery_menu: [
        {
          name: "Return Delivery Daikin",
          access: ["View", "Acknowledge", "Approve", "Unpick", "Completed"],
          key: ["view", "acknowledge", "approve", "unpick", "completed"],
          view: false,
          acknowledge: false,
          approve: false,
          unpick: false,
          completed: false,
        },
        {
          name: "Return Delivery Sumitomo",
          access: ["View", "Acknowledge", "Approve", "Unpick", "Completed"],
          key: ["view", "acknowledge", "approve", "unpick", "completed"],
          view: false,
          acknowledge: false,
          approve: false,
          unpick: false,
          completed: false,
        },
        {
          name: "Manual Return Delivery Daikin",
          access: ["View", "Acknowledge", "Approve", "Unpick", "Completed", "Manual Return"],
          key: ["view", "acknowledge", "approve", "unpick", "completed", "manualReturn"],
          view: false,
          acknowledge: false,
          approve: false,
          unpick: false,
          completed: false,
          manualReturn: false,
        },
        {
          name: "Manual Return Delivery Sumitomo",
          access: ["View", "Acknowledge", "Approve", "Unpick", "Completed", "Manual Return"],
          key: ["view", "acknowledge", "approve", "unpick", "completed", "manualReturn"],
          view: false,
          acknowledge: false,
          approve: false,
          unpick: false,
          completed: false,
          manualReturn: false,
        },
      ],
      redelivery_menu: [
        {
          name: "Redelivery Daikin",
          access: ["View", "Accept"],
          key: ["view", "accept"],
          view: false,
          accept: false,
        },
        {
          name: "Redelivery Sumitomo",
          access: ["View", "Accept"],
          key: ["view", "accept"],
          view: false,
          accept: false,
        },
      ],
      manualcreate_menu: [
        {
          name: "Manual Create Delivery",
          access: ["View", "Add"],
          key: ["view", "add"],
          view: false,
          add: false,
        },
        {
          name: "Manual Create Inventory",
          access: ["View", "Add"],
          key: ["view", "add"],
          view: false,
          add: false,
        },
        {
          name: "Manual Create By Upload",
          access: ["View", "Add"],
          key: ["view", "add"],
          view: false,
          add: false,
        },
      ],
      utilitytools_menu: [
        {
          name: "Mass DO Update",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Transaction Log Record",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Edit DO Status",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Clear Amk Spare Part",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Mass Update Email",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Return Order Type Exception",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Replace DO",
          access: ["View"],
          key: ["view"],
          view: false,
        }
      ],
      retrigger_api_menu: [
        {
          name: "Resend to SAP",
          access: ["View", "Resend"],
          key: ["view", "resend"],
          view: false,
          resend: false,
        },
      ],
      warranty_menu: [
        {
          name: "Project Warranty",
          access: ["View", "Export", "Add", "Edit", "Delete", "Generate"],
          key: ["view", "export", "add", "edit", "delete", "generate"],
          view: false,
          export: false,
          add: false,
          edit: false,
          delete: false,
          generate: false,
        },
        {
          name: "Paperless Warranty",
          access: ["View", "Export", "Add", "Edit", "Delete", "Cancel","Deregister"],
          key: ["view", "export", "add", "edit", "delete", "cancel","deregister"],
          view: false,
          export: false,
          add: false,
          edit: false,
          delete: false,
          cancel: false,
          deregister:false
        },
        {
          name: "Equipment Warranty",
          access: ["View", "Export", "Add", "Edit", "Delete", "Generate"],
          key: ["view", "export", "add", "edit", "delete", "generate"],
          view: false,
          export: false,
          add: false,
          edit: false,
          delete: false,
          generate: false,
        },
        {
          name: "Warranty Status",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "Pending Review",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
        {
          name: "All Warranty",
          access: ["View"],
          key: ["view"],
          view: false,
        },
      ],
      settings_menu: [
        {
          name: "Role",
          access: ["Add", "View", "Edit", "Delete"],
          key: ["add", "view", "edit", "delete"],
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "User Management",
          access: ["Add", "View", "Edit", "Delete"],
          key: ["add", "view", "edit", "delete"],
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "Holiday Management",
          access: ["Add", "View", "Edit", "Delete"],
          key: ["add", "view", "edit", "delete"],
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        {
          name: "GST",
          access: ["Add", "View", "Edit", "Delete"],
          key: ["add", "view", "edit", "delete"],
          add: false,
          view: false,
          edit: false,
          delete: false,
        },
        
        {
          name: "Dashboard Password",
          access: ["View", "Edit"],
          key: ["view", "edit"],
          view: false,
          edit: false,
        },
      ],
      report_menu: [
        {
          name: "RSP Order Report",
          view: false,
        },
        {
          name: "Inventory Report",
          view: false,
        },
        {
          name: "PCode Report",
          view: false,
        },
        {
          name: "Promo Code Report",
          view: false,
        },
        {
          name: "DO Report",
          view: false,
        },
        {
          name: "Expiring Warranty Report",
          view: false,
        },
        {
          name: "Expired Warranty Report",
          view: false,
        },
      ],
    };
  },
  methods: {
    row(array) {
      return [...Array(Math.ceil(array.length / 4)).keys()];
    },
    viewEvent(data, menuName) {
      let menu = this[menuName].find((u) => u.name === data.name);
      console.info("view event pressed ", data);
      console.info("menu ", menu);
      if (data.view === false) {
        var keyArray = Object.keys(menu).filter((el) => {
          return (
            String(el) !== "name" &&
            String(el) !== "access" &&
            String(el) !== "key"
          );
        });
        console.info("keyArray ", keyArray);
        for (let key of keyArray) {
          menu[key] = false;
        }
      }
    },
    selectAll(module) {
      this[module] = this[`${module}s`];
    },
    clear(module) {
      this[module] = [];
    },
    addNewRole() {
      this.isLoading = true
      this.form.permission = {
        dashboard_menu: this.dashboard_menu,
        masterdata_menu: this.masterdata_menu,
        warranty_master_menu: this.warranty_master_menu,
        order_menu: this.order_menu,
        inventory_menu: this.inventory_menu,
        sales_order_menu: this.sales_order_menu,
        delivery_order_menu: this.delivery_order_menu,
        self_collect_menu: this.self_collect_menu,
        shipping_menu: this.shipping_menu,
        return_delivery_menu: this.return_delivery_menu,
        redelivery_menu: this.redelivery_menu,
        manualcreate_menu: this.manualcreate_menu,
        utilitytools_menu: this.utilitytools_menu,
        retrigger_api_menu: this.retrigger_api_menu,
        warranty_menu: this.warranty_menu,
        settings_menu: this.settings_menu,
        report_menu: this.report_menu,
      };
      console.log(this.form);
      this.$store
        .dispatch("role/addRole", {
          ...this.form,
        })
        .then(() => {
          this.isLoading = false
          this.$bvToast.toast("Successfully Add Role", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "role" });
        })
        .catch((err) => {
          this.isLoading = false
          console.log({ err });
          this.$bvToast.toast(
            `You have failed to create a user ${this.form.name}`,
            {
              variant: "danger",
              solid: true,
              title: err.response.data.errors[0].message,
            }
          );
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/getActiveUser"];
    },
  },
  created() {
    document.title = "Add Role | RSP";
  },
  mounted() {
    this.$store.dispatch("auth/fetchUser").catch((err) => console.log(err));
  },
};
</script>

<style>
.permission-border {
  border-radius: 10px;
  border: 2px solid #cccccc;
  padding: 20px;
  margin: 5px;
}
</style>
